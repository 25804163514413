import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { required } from '../../util/inputValidators'

import HelpText from './HelpText'
import useTranslate from '../../translations/useTranslate'

const isRequired = validators => validators.reduce((requiredFound, validator) => requiredFound || validator === required, false)

const RequiredDenotation = ({ validators }) => isRequired(validators) ? <span className='text-danger'>*</span> : null

const FieldDecorations = ({ children, className, label, error, visited, submitted, validators = [], fieldId, ariaLabelledById }) => {
  const { t } = useTranslate()

  return (
    <FormGroup className={className}>
      {label ? <Label for={fieldId}>{t(label)} <RequiredDenotation validators={validators} /></Label> : null}
      {children}
      {error && submitted ? <div className='invalid-field-message' aria-label={t(error)}>{t(error)}</div> : null}
      <HelpText label={label} fieldId={fieldId} ariaLabelledById={ariaLabelledById} />
    </FormGroup>
  )
}

export default FieldDecorations
