import { useField, useFieldValue } from 'amiable-forms'
import { Input } from 'reactstrap'

import FieldDecorations from '../Fields/FieldDecorations'
import { required, vin } from '../../util/inputValidators'
import { normalizeUpperCaseWithNoSpaces } from '../../util/normalizations'

const isInvalid = (valid, submitted) => {
  if (valid || !submitted) return false
  return !valid
}

const VinOrPlateField = ({ name, label }) => {
  const confirmVin = useFieldValue({ name: 'confirmVin' })
  const validators = confirmVin ? [required] : [required, vin]
  const { value, onChange, onBlur, error, submitted, valid, onFocus, visited } = useField({ name, validators, parse: normalizeUpperCaseWithNoSpaces })
  const invalid = isInvalid(valid, submitted)

  return (
    <FieldDecorations label={label} error={error} visited={visited} submitted={submitted} validators={validators}>
      <Input name={name} invalid={invalid} onChange={onChange} value={value} onBlur={onBlur} onFocus={onFocus} />
    </FieldDecorations>
  )
}

export default VinOrPlateField
