import { Col, Row } from 'reactstrap'
import useTranslate from '../../translations/useTranslate'

const Section = ({ title, className, children }) => {
  const { t } = useTranslate()
  return (
    <>
      <Row className='section'>
        <Col>
          <div className='section-title'> {t(title)}</div>
        </Col>
      </Row>
      <Row className={className || ''}>
        <Col className='px-4 py-3'>
          {children}
        </Col>
      </Row>
    </>
  )
}

export default Section
