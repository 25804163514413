import { AmiableForm, useFieldIsValid, useFieldValue, useSubmit } from 'amiable-forms'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactMarkdown from 'react-markdown'
import { useLocation } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

import useTranslate from '../../translations/useTranslate'
import { redirect } from '../../util/redirect'
import transformBuilder from '../../util/transformBuilder'
import VinOrPlateField from '../VinOrPlateField'
import ConfirmVin from './ConfirmVin'
import { transformVin } from './transformVehicleSearch'
import useRecaptcha from './useRecaptcha'

import './index.scss'

const RECAPTCHA_SITE_KEY = '6Ldna_4ZAAAAAKzRbiKF9xv91A0E3p1AP7e-Xut9'

const SearchButton = ({ loading }) => {
  const { t } = useTranslate()
  const { onSubmit, submitted } = useSubmit()
  const vinOrPlateValue = useFieldValue({ name: 'vinOrPlate' })
  const vinOrPlateValid = useFieldIsValid({ name: 'vinOrPlate' })
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setDisabled((!vinOrPlateValid && submitted))
  }, [setDisabled, vinOrPlateValid, submitted, vinOrPlateValue])

  return (
    <Row form>
      <Col sm={12} md={6}>
        <Button color='primary' className='btn-block' disabled={loading || disabled} onClick={onSubmit}>{t('Controls.search')}</Button>
      </Col>
    </Row>
  )
}

export const VehicleSearch = () => {
  const { t } = useTranslate()
  const { ref, execute, loading } = useRecaptcha()
  const location = useLocation()
  const params = new window.URLSearchParams(location.search)

  const navigate = async ({ vinOrPlate }) => {
    await execute()

    redirect(`notices/${vinOrPlate}`)
  }

  const transform = transformBuilder(transformVin)

  return (
    <AmiableForm process={navigate} transform={transform} initialValues={{ vinOrPlate: params.get('vinOrPlate') }}>
      <div className='search-box'>
        <h4 className='title'>
          {t('HomePage.title')}
        </h4>
        <Row form>
          <Col md={12} lg={12}>
            <VinOrPlateField name='vinOrPlate' label={t('HomePage.vinOrPlate')} />
          </Col>
        </Row>
        <ConfirmVin name='confirmVin' />
        <SearchButton loading={loading} />
        <hr />
        <ReactMarkdown className='privacyTerms'>
          {t('HomePage.privacyTermsStatement')}
        </ReactMarkdown>
        <ReCAPTCHA ref={ref} sitekey={RECAPTCHA_SITE_KEY} size='invisible' />
      </div>
    </AmiableForm>
  )
}

export default VehicleSearch
