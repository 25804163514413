import React, { createContext } from 'react'

import useTranslate from '../../translations/useTranslate'
import './index.scss'

export const LoadingContext = createContext(false)

const DisplayField = ({ title, children, className = '' }) => {
  const { t } = useTranslate()
  return (
    <div className={className}>
      {title ? <div className='display-field-title'>{t(title)}:</div> : null}
      <div>{children}</div>
    </div>
  )
}

export default DisplayField
