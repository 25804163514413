import React from 'react'
import { Container, Jumbotron, Row, Col } from 'reactstrap'

import Footer from '../Footer'
import HomePageSection from '../HomePageSection'
import VehicleSearch from '../VehicleSearch'
import logo from '../../images/logo-carlocate-color.png'
import useTranslate from '../../translations/useTranslate'

import './index.scss'

const TextSection = () => {
  const { t } = useTranslate()
  return (
    <Row className='align-items-center my-4'>
      <Col className='text-center'>{t('HomePage.findVehicleText')}</Col>
      <Col className='text-center'>{t('HomePage.searchDbText')}</Col>
    </Row>
  )
}

const CallToActionSection = () => {
  const { t } = useTranslate()
  return (
    <div className='callToAction'><h3>{t('HomePage.callToActionText')}</h3></div>
  )
}

const HomePage = () => {
  return (
    <div className='homepage'>
      <header>
        <Jumbotron fluid className='background d-flex mb-0'>
          <Container className='m-auto'>
            <Row>
              <Col md={12} lg={6} className='text-center'>
                <img src={logo} alt='CarLocate' className='logo mx-auto mx-md-0' />
              </Col>
              <Col md={12} lg={6} className='pt-5 mt-5'>
                <VehicleSearch />
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </header>
      <HomePageSection bgcolor='bg-gray-100 border-bottom border-top'>
        <TextSection />
      </HomePageSection>
      <HomePageSection bgcolor='bg-gray-200 border-bottom'>
        <CallToActionSection />
      </HomePageSection>
      <Container>
        <Footer />
      </Container>
    </div>
  )
}

export default HomePage
