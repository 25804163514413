import { actionTypes as asyncOpTypes } from 'use-async-ops-redux'
import * as asyncOpNames from '../constants/asyncOpNames'

const initialState = {
  id: '',
  noticeType: '',
  identificationNumber: '',
  licensePlateNumber: '',
  licensePlateStateCode: '',
  lienorName: '',
  lienorAddress: {
    streetAddress: '',
    city: '',
    stateCode: '',
    zipCode: '',
    zipPlus: ''
  },
  locationAddress: {
    streetAddress: '',
    city: '',
    stateCode: '',
    zipCode: '',
    zipPlus: ''
  },
  lienNoticeDetails: [],
  noticeOfSaleDetails: [],
  repairDropOffDate: '',
  repairCompletionDate: '',
  repairCompletionNotificationDate: '',
  repairAmount: '',
  dailyStorageRateAmount: '',

  towDate: '',
  outOfStateVesselRegNumber: '',
  documentedVesselNumber: '',
  floridaVesselRegNumber: '',
  amountDue: ''
}

const search = (state, action) => ({
  ...initialState,
  ...action.response
})

const asyncOpCompleteReducer = (state, action) => {
  switch (action.name) {
    case asyncOpNames.VEHICLE_SEARCH: return search(state, action)
    default: return state
  }
}

const notice = (state = initialState, action) => {
  switch (action.type) {
    case asyncOpTypes.COMPLETE: return asyncOpCompleteReducer(state, action)
    default: return state
  }
}

export default notice
