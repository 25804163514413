import React from 'react'
import { Row, Col, Button } from 'reactstrap'

import logo from '../../images/logo-carlocate-color.png'
import { PRINT } from '../../constants/iconTypes'
import mapPin from '../../images/map-pin.png'
import Icon from '../Icon'
import useTranslate from '../../translations/useTranslate'
import './index.scss'

const Header = () => {
  const { t } = useTranslate()

  return (
    <div className='header'>
      <Row className='align-items-center'>
        <Col sm={12} md={6} className='order-1 order-md-1 logo-col py-3'>
          <div className='logo'>
            <a href='/' alt={t('ImageAltText.altHome')} className='d-block'>
              <img src={logo} alt='CarLocate' />
            </a>
          </div>
        </Col>
        <Col sm={12} md={6} className='order-3 order-md-2 print-col py-3 text-md-right text-center'>
          <Button onClick={() => window.print()} color='light' className='print-button'><Icon name={PRINT} />{t('Controls.print')}</Button>
        </Col>
        <Col sm={12} className='order-2 order-md-3'>
          <img src={mapPin} alt={t('ImageAltText.altMapPin')} className='map-pin' />
          <div className='header-map'>
            <div className='map' />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Header
