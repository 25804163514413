import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const HomePageSection = ({ bgcolor = '', className = '', children }) => {
  return (
    <section className={bgcolor}>
      <Container>
        <Row className={className}>
          <Col>
            {children}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HomePageSection
