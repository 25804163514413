import i18n from '../translations/i18n'

import validateVinCheckDigit from './validateVinCheckDigit'

export const required = value => value ? undefined : 'ErrorFeedback.required'

export const numeric = value =>
  (!value || /^[0-9]*?$/i.test(value)) ? undefined : 'ErrorFeedback.invalid'

export const zip = value =>
  (!value || /^[0-9]{5}(-[0-9]{4})?$/i.test(value)) ? undefined : 'ErrorFeedback.invalid'

export const phone = value =>
  (!value || /^\d{3}-\d{3}-\d{4}$/.test(value)) ? undefined : 'ErrorFeedback.invalid'

export const time = value =>
  (!value || /^\d{1,2}:\d{2} [AP][M]$/.test(value)) ? undefined : 'ErrorFeedback.invalid'

export const maxLength = max => value =>
  value && value.length > max ? i18n.t('ErrorFeedback.maxLength', { max }) : undefined

export const fixedLength = l => value =>
  value && value.length !== l ? i18n.t('ErrorFeedback.fixedLength', { length: l }) : undefined

export const maxValue = max => value =>
  value && value > max ? i18n.t('ErrorFeedback.maxValue', { max }) : undefined

export const email = value =>
  (!value || /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i.test(value)) ? undefined : 'ErrorFeedback.invalid'

export const vin = value => {
  if (!value || value?.length <= 10) return undefined
  if (value.length !== 17) return 'Validations.vin.mustBe17'
  if (value.replace(/[^A-Z0-9]+/ig, '') !== value) return 'Validations.vin.invalidCharacters'
  if (!(validateVinCheckDigit(value))) return 'Validations.vin.checkdigit'
  return undefined
}

export const creditcard = value => {
  if (!value) return undefined
  if (value.length !== 16) return 'ErrorFeedback.invalid'
  if (value.replace(/[^0-9]+/g, '') !== value) return 'ErrorFeedback.invalid'
  if (!luhnCheck(value)) return 'ErrorFeedback.invalid'
  return undefined
}

const luhnCheck = val => {
  let sum = 0
  for (let i = 0; i < val.length; i++) {
    let intVal = parseInt(val.substr(i, 1))
    if (i % 2 === 0) {
      intVal *= 2
      if (intVal > 9) {
        intVal = 1 + (intVal % 10)
      }
    }
    sum += intVal
  }
  return (sum % 10) === 0
}

export const oneOrTheOther = secondValue => firstValue =>
  secondValue && firstValue ? 'ErrorFeedback.oneOrTheOther' : undefined
