import React from 'react'

import privacyPolicy from '../../translations/files/en/privacyPolicy.en.md'
import markdownRenderers from '../RemoteMarkdown/markdownRenderers'
import RemoteMarkdown from '../RemoteMarkdown'
import PageContainer from '../PageContainer'

const PrivacyPolicy = () =>
  <PageContainer>
    <RemoteMarkdown url={privacyPolicy} renderers={markdownRenderers} escapeHtml={false} />
  </PageContainer>

export default PrivacyPolicy
