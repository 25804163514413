import { convertServerDate } from '@autodatadirect/service-date'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import DisplayField from '../DisplayField'
import Section from '../Section'

const Charges = () => {
  const { administrativeCharge, storageCharge, towingCharge, recoveryCharge, releaseCharge } = useSelector(state => state.notice)

  return (
    <Container fluid className='px-0'>
      <Row className='pb-2'>
        <Col>
          <DisplayField title='SaleInformation.administrativeCharge'>
            {administrativeCharge || '$0.00'}
          </DisplayField>
        </Col>
      </Row>
      <Row className='pb-2'>
        <Col>
          <DisplayField title='SaleInformation.storageCharge'>
            {storageCharge || '$0.00'}
          </DisplayField>
        </Col>
      </Row>
      <Row className='pb-2'>
        <Col>
          <DisplayField title='SaleInformation.towingCharge'>
            {towingCharge || '$0.00'}
          </DisplayField>
        </Col>
      </Row>
      <Row className='pb-2'>
        <Col>
          <DisplayField title='SaleInformation.recoveryCharge'>
            {recoveryCharge || '$0.00'}
          </DisplayField>
        </Col>
      </Row>
      <Row className='pb-2'>
        <Col>
          <DisplayField title='SaleInformation.lienReleaseCharge'>
            {releaseCharge || '$0.00'}
          </DisplayField>
        </Col>
      </Row>
    </Container>
  )
}

const Address = ({ streetAddress, city, stateCode, zipCode, zipPlus }) =>
  <>
    <div>{streetAddress}</div>
    <div>{city}, {stateCode} {zipCode}{zipPlus ? '-' + zipPlus : ''}</div>
  </>

const SaleInformation = () => {
  const { saleDate, saleTime, saleAddress } = useSelector(state => state.notice)

  return (
    <Section title='SectionTitle.saleInformation'>
      <Row>
        <Col md={4}>
          <DisplayField title='SaleInformation.location'><Address {...saleAddress} /></DisplayField>
        </Col>
        <Col md={4}>
          <DisplayField title='SaleInformation.date'>{convertServerDate(saleDate)} {saleTime}</DisplayField>
        </Col>
        <Col md={4}>
          <Charges />
        </Col>
      </Row>
    </Section>
  )
}

export default SaleInformation
