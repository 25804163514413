import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const Icon = ({ name, onClick, title, className }) =>
  <span title={title} aria-label={title} className={`carlocateIcons icon carlocateIcons-${name} ${className || ''}`} onClick={onClick} />

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string
}

export default Icon
