import Chance from 'chance'
import { formatServerDate } from '@autodatadirect/service-date'

const chance = new Chance()
const randomDateTime = (start, end) => formatServerDate(new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())))

const makeRandomNotice = ({ vin }) => ({
  id: chance.guid(),
  noticeType: chance.pickone(['TOWING', 'REPAIR']),
  identificationNumber: vin,
  licensePlateNumber: chance.hash({ casing: 'upper', length: chance.integer({ min: 3, max: 8 }) }),
  licensePlateStateCode: chance.state(),
  lienorName: chance.company(),
  lienorAddress: {
    streetAddress: chance.address(),
    city: chance.city(),
    stateCode: chance.state(),
    zipCode: chance.zip(),
    zipPlus: chance.integer({ min: 1000, max: 9999 })
  },
  locationAddress: {
    streetAddress: chance.address(),
    city: chance.city(),
    stateCode: chance.state(),
    zipCode: chance.zip(),
    zipPlus: chance.integer({ min: 1000, max: 9999 })
  },
  lienNoticeDetails: [
    {
      recipientType: chance.pickone(['Owner', 'Customer', 'LienHolder', 'InsuranceCompany', 'Other']),
      recipientName: chance.name(),
      mailDate: randomDateTime(new Date(2020, 0, 1), new Date()),
      deliveryDate: randomDateTime(new Date(2020, 0, 1), new Date()),
      uspsArticleNumber: chance.hash({ casing: 'upper', length: 15 })
    }
  ],
  noticeOfSaleDetails: [
    {
      recipientType: chance.pickone(['Owner', 'Customer', 'LienHolder', 'InsuranceCompany', 'Other']),
      recipientName: chance.name(),
      mailDate: randomDateTime(new Date(2020, 0, 1), new Date()),
      deliveryDate: randomDateTime(new Date(2020, 0, 1), new Date()),
      uspsArticleNumber: chance.hash({ casing: 'upper', length: 15 })
    }
  ],
  // The following fields are only present when noticeType = REPAIR

  repairDropOffDate: randomDateTime(new Date(2020, 0, 1), new Date()),
  repairCompletionDate: randomDateTime(new Date(2020, 0, 1), new Date()),
  repairCompletionNotificationDate: randomDateTime(new Date(2020, 0, 1), new Date()),
  repairAmount: chance.floating({ min: 20, max: 50, fixed: 2 }),
  dailyStorageRateAmount: chance.integer({ min: 20, max: 35 }),
  // The following fields are only present when noticeType = TOWING

  towDate: randomDateTime(new Date(2020, 0, 1), new Date()),
  outOfStateVesselRegNumber: chance.string({ length: 15, numeric: true }),
  documentedVesselNumber: chance.string({ length: 10, numeric: true }),
  floridaVesselRegNumber: 'FL-' + chance.string({ length: 4, numeric: true }) + ' ' + chance.string({ case: 'upper', length: 2, alpha: true }),
  amountDue: chance.floating({ min: 5, max: 50, fixed: 2 })
})

export default makeRandomNotice
