import { enableMock, disableMock } from 'use-async-ops'

import { get } from './tools/registry'
import createDb from './tools/createMockServiceDb'
import './methods/getNotice'

export const noticesDb = createDb(window.localStorage)('CARLOCATE_DB')

const ENABLED_KEY = 'carlocateDemoModeEnabled'

const clearDbs = () => {
  noticesDb.clear()
}

export const enable = () => {
  clearDbs()
  enableMock()
  window.localStorage.setItem(ENABLED_KEY, 'true')
}

export const disable = () => {
  disableMock()
  clearDbs()
  window.localStorage.removeItem(ENABLED_KEY)
}

export const isEnabled = () => window.localStorage.getItem(ENABLED_KEY) === 'true'

window.DEMO_MODE = { enable, disable, isEnabled }

export default get()
