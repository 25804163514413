import { convertServerDate } from '@autodatadirect/service-date'
import { Col, Row } from 'reactstrap'
import * as iconTypes from '../../constants/iconTypes'
import useTranslate from '../../translations/useTranslate'
import isEmpty from '../../util/isEmpty'
import { normalizePhone } from '../../util/normalizations'
import DisplayField from '../DisplayField'
import Icon from '../Icon'
import PageContainer from '../PageContainer'
import SaleInformation from '../SaleInformation'
import Section from '../Section'

import './index.scss'

const NoticeType = ({ type }) => {
  const { t } = useTranslate()
  const icon = type === 'TOWING' ? 'TOW_TRUCK' : 'AUTO_REPAIR'
  const noticeText = type === 'TOWING' ? 'VehicleDetails.noticeTowing' : 'VehicleDetails.noticeRepair'
  return (
    <>
      <Icon name={iconTypes[icon]} className='notice-icon' />
      <span className='notice-type'>
        {t(noticeText)}
      </span>
    </>
  )
}

const VehicleDetails = ({ noticeType, identificationNumber, licensePlateNumber, licensePlateStateCode }) =>
  <div className='vehicle-details mt-3'>
    <Row className='align-items-center'>
      <Col sm={12} md='auto' className='text-center text-md-left mb-3'><NoticeType type={noticeType} /></Col>
      <Col>
        <Row className='row-cols-md-3'>
          <Col sm='12' className='mb-3'><DisplayField title='VehicleDetails.identificationNumber'>{identificationNumber}</DisplayField></Col>
          <Col xs='6' className='mb-3'><DisplayField title='VehicleDetails.licensePlateNumber'>{licensePlateNumber}</DisplayField></Col>
          <Col xs='6' className='mb-3'><DisplayField title='VehicleDetails.licensePlateStateCode'>{licensePlateStateCode}</DisplayField></Col>
        </Row>
      </Col>
    </Row>
  </div>

const Address = ({ streetAddress, city, stateCode, zipCode, zipPlus }) =>
  <>
    <div>{streetAddress}</div>
    <div>{city}, {stateCode} {zipCode}{zipPlus ? '-' + zipPlus : ''}</div>
  </>

const Notices = ({ notices }) =>
  <Section title='SectionTitle.notice'>
    {notices.map((n, i) => <Notice key={i} {...n} />)}
  </Section>

const Notice = ({ deliveryDate, mailDate, uspsArticleNumber }) => {
  const { t } = useTranslate()

  return (
    <Row>
      <Col sm={12} md={4} className='mb-3'><DisplayField title='Notice.mailDate' name='mailDate'>{convertServerDate(mailDate)}</DisplayField></Col>
      <Col sm={12} md={4} className='mb-3'><DisplayField title='Notice.deliveryDate' name='deliveryDate'>{deliveryDate ? convertServerDate(deliveryDate) : t('Notice.notDelivered')}</DisplayField></Col>
      <Col sm={12} md={4} className='mb-3'><DisplayField title='Notice.uspsArticleNumber' name='uspsArticleNumber'>{uspsArticleNumber}</DisplayField></Col>
    </Row>
  )
}

const TowingInfo = ({ towDate, noticeOfSaleDetails, lienNoticeDetails, saleDate }) =>
  <>
    <Section title='SectionTitle.towing'>
      <Row>
        <Col className='mb-3'><DisplayField title='TowingInfo.towDate'>{convertServerDate(towDate)}</DisplayField></Col>
      </Row>
    </Section>
    <Notices notices={isEmpty(lienNoticeDetails) ? noticeOfSaleDetails : lienNoticeDetails} />
    {saleDate && <SaleInformation />}
  </>

const MechanicInfo = ({ repairDropOffDate, repairCompletionDate, repairCompletionNotificationDate, lienNoticeDetails }) =>
  <>
    <Section title='SectionTitle.repair'>
      <Row className='row-cols-1 row-cols-md'>
        <Col>
          <Row>
            <Col className='mb-3'><DisplayField title='MechanicInfo.repairDropOffDate'>{convertServerDate(repairDropOffDate)}</DisplayField></Col>
            <Col className='mb-3'><DisplayField title='MechanicInfo.repairCompletionDate'>{convertServerDate(repairCompletionDate)}</DisplayField></Col>
            <Col className='mb-3'><DisplayField title='MechanicInfo.repairCompletionNotificationDate'>{convertServerDate(repairCompletionNotificationDate)}</DisplayField></Col>
          </Row>
        </Col>
      </Row>
    </Section>
    <Notices notices={lienNoticeDetails} />
  </>

const CommonInfo = ({ notice }) =>
  <>
    <VehicleDetails {...notice} />
    <Section title='SectionTitle.vehicleLocation'>
      <Row>
        <Col sm={12} md={4} className='mb-3'><DisplayField title='LienInfo.locationAddress'><Address {...notice.locationAddress} /></DisplayField></Col>
        <Col sm={12} md={4} className='mb-3'>
          <DisplayField title='LienInfo.lienorAddress'>
            <div>{notice.lienorName}</div>
            <Address {...notice.lienorAddress} />
          </DisplayField>
        </Col>
        <Col sm={12} md={4} className='mb-3'>
          <DisplayField title='LienInfo.lienorPhone'>
            {normalizePhone(notice.lienorPhoneNumber)}
          </DisplayField>
        </Col>
      </Row>
    </Section>
  </>

const NoticesPage = ({ notice }) => {
  const { t } = useTranslate()
  return (
    <PageContainer>
      <CommonInfo notice={notice} />
      {notice.noticeType === 'TOWING' ? <TowingInfo {...notice} /> : <MechanicInfo {...notice} />}
      <div className='alert alert-warning'>{t('Pagewrapper.notice')}</div>
    </PageContainer>
  )
}

export default NoticesPage
