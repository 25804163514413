import * as actionTypes from '../constants/actionTypes'

const storeToken = (state, action) => action.token || ''

const recaptcha = (state = '', action) => {
  switch (action.type) {
    case actionTypes.STORE_TOKEN: return storeToken(state, action)
    default: return state
  }
}

export default recaptcha
