import React from 'react'

import useTranslate from '../../translations/useTranslate'
import PageContainer from '../PageContainer'

import './index.scss'

const LoadingPage = ({ className = '', children }) => {
  const { t } = useTranslate()
  return (
    <PageContainer>
      <svg className='arrow-circle-down bounce' viewBox='0 0 512 512'><path d='M417 165.9c0-88.92-72.08-161-161-161S95 77 95 165.9c0 28.4 7.37 55.1 20.3 78.27h-.01L256 507.1l140.72-262.9C409.63 221 417 194.32 417 165.9zM256 85.1c44.63 0 80.8 36.18 80.8 80.8s-36.18 80.8-80.8 80.8-80.8-36.18-80.8-80.8 36.18-80.8 80.8-80.8z' /></svg>
      <div className='text-center loading-text mb-5'>{t('LoadingPage.loading')}...</div>
    </PageContainer>
  )
}

export default LoadingPage
