import React from 'react'
import ReactMarkdown from 'react-markdown'

import useTranslate from '../../translations/useTranslate'
import PageContainer from '../PageContainer'

import './index.scss'

const ErrorPage = ({ vinOrPlate, error }) => {
  const { t } = useTranslate()

  return (
    <PageContainer>
      <div className='error-page text-center mt-3'>
        <ReactMarkdown>
          {t(error.code !== 'RECORD_NOT_FOUND' ? 'ErrorPage.errorMessage' : 'ErrorPage.recordNotFound', { vinOrPlate })}
        </ReactMarkdown>
      </div>
    </PageContainer>
  )
}

export default ErrorPage
