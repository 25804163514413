import { useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as actionTypes from '../../constants/actionTypes'

const useRecaptcha = () => {
  const dispatch = useDispatch()
  const ref = createRef()
  const [loading, setLoading] = useState(false)

  const token = useSelector(state => state.recaptcha)
  const setToken = token => dispatch({ type: actionTypes.STORE_TOKEN, token })
  const doneLoading = () => setLoading(false)

  const execute = () => {
    setLoading(true)
    return ref.current.executeAsync()
      .finally(() => doneLoading())
      .then(setToken, console.err)
  }

  return {
    ref,
    execute,
    loading,
    token
  }
}

export default useRecaptcha
