import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAsyncOp } from 'use-async-ops'

import * as asyncOpNames from '../../constants/asyncOpNames'
import { redirect } from '../../util/redirect'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import NoResults from '../NoResults'
import Component from './component'

const NoticesPage = () => {
  const { vinOrPlate } = useParams()
  const notice = useSelector(state => state.notice)
  const token = useSelector(state => state.recaptcha)
  const { call, loading, error } = useAsyncOp({ name: asyncOpNames.VEHICLE_SEARCH })

  useEffect(() => {
    if (!vinOrPlate || !token) return
    call({ vinOrPlate, token })
  }, [call, vinOrPlate, token])

  if (loading) {
    return <LoadingPage />
  }

  if (error) {
    return <ErrorPage vinOrPlate={vinOrPlate} error={error} />
  }

  if (!token) {
    redirect('')
  }

  if (!notice) {
    // TODO replace with explicit Error message
    return <NoResults vinOrPlate={vinOrPlate} />
  }

  return <Component notice={notice} />
}

export default NoticesPage
