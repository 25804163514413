import React from 'react'

import termsOfUse from '../../translations/files/en/termsOfUse.en.md'
import markdownRenderers from '../RemoteMarkdown/markdownRenderers'
import RemoteMarkdown from '../RemoteMarkdown'
import PageContainer from '../PageContainer'

const TermsOfUse = () =>
  <PageContainer>
    <RemoteMarkdown url={termsOfUse} renderers={markdownRenderers} escapeHtml={false} />
  </PageContainer>

export default TermsOfUse
