export const getFieldValue = (values, fieldName) => {
  if (!values || !fieldName) return
  const parts = fieldName.split('.')

  for (const part of parts) {
    values = values[part]

    if (values == null) return
  }

  return values
}

export const fieldHasChanged = ({ current, next }, fieldNames) => {
  if (!fieldNames || fieldNames.length === 0) return false

  let hasChanged = false

  fieldNames.forEach(fieldName => {
    const currVal = getFieldValue(current.values, fieldName)
    const nextVal = getFieldValue(next.values, fieldName)

    if (currVal !== nextVal) {
      hasChanged = true
    }
  })

  return hasChanged
}
