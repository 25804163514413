import React from 'react'

const extractText = children => {
  try {
    return children[0].props.children || ''
  } catch (err) {
    return ''
  }
}

const toLinkSafeText = s => s.toLowerCase().replace(/[^\w\- ]+/g, ' ').replace(/\s+/g, '-').replace(/-+$/, '')

const heading = ({ level, children }) => {
  const linkText = toLinkSafeText(extractText(children))
  const headerProps = {}
  if (linkText) headerProps.id = linkText
  switch (level) {
    case 1: return <h1 {...headerProps}>{children}</h1>
    case 2: return <h2 {...headerProps}>{children}</h2>
    case 3: return <h3 {...headerProps}>{children}</h3>
    case 4: return <h4 {...headerProps}>{children}</h4>
    case 5: return <h5 {...headerProps}>{children}</h5>
    default: return <h6 {...headerProps}>{children}</h6>
  }
}

const link = ({ href, children }) => {
  const props = { href }
  if (!href.startsWith('#')) {
    props.rel = 'noopener noreferrer'
    props.target = '_blank'
  }

  return <a {...props}>{children}</a>
}

const image = ({ alt, src, title }) => {
  return <img alt={alt} src={src} title={title} />
}

const paragraph = ({ children }) => <p>{children}</p>

const markdownRenderers = { link, image, heading, paragraph }

export default markdownRenderers
