import React from 'react'

import useTranslate from '../../translations/useTranslate'
import PageContainer from '../PageContainer'
import { Row, Col, Button } from 'reactstrap'

import './index.scss'

const NoResults = ({ vinOrPlate }) => {
  const { t } = useTranslate()

  return (
    <PageContainer>
      <Row className='justify-content-center mt-3'>
        <Col xs={12} className='text-center mb-3'>
          <span className='no-results'>{t('NoItemsFound.noResults', { vinOrPlate })}</span>
        </Col>
        <Col sm={12} md={3}>
          <Button href='/' color='light' className='w-md-25 w-100'>{t('Controls.searchAgain')}</Button>
        </Col>
      </Row>
    </PageContainer>
  )
}

export default NoResults
