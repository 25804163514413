/*
 *  https://en.wikipedia.org/wiki/Vehicle_identification_number#Weights_used_in_calculation
 */
const WEIGHT_POSITIONS = [8, 7, 6, 5, 4, 3, 2, 10, -1, 9, 8, 7, 6, 5,
  4, 3, 2]

/*
 *  values that are not -1 are 0-9 and ABCDEFGH JKLMN P RSTUVWXYZ (no I,O,Q)
 *  This array represents ascii codes 0-90 which goes up to Z (90)
 *  The actual values are based on EBCDIC (a bummer of an IBM transliteration standard) and are relevant.
 */
const VALUES_FROM_ASCII = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, -1, -1, -1, -1, -1, -1,
  -1, 1, 2, 3, 4, 5, 6, 7, 8, -1, 1, 2, 3, 4, 5, -1, 7, -1, 9, 2, 3,
  4, 5, 6, 7, 8, 9]

/*
 *  https://en.wikipedia.org/wiki/Vehicle_identification_number#Check-digit_calculation
 *  the 9th digit can be 0-9 or X (to denote 10) since it's (sum % 11)
 *  This array represents ascii codes 0-88 which goes up to X (88)
 *  The actual values are unimportant other than being >= 0
 */
const CHECK_DIGIT_FROM_ASCII = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  -1, -1, -1, -1, -1, -1, -1, -1, 10]

const sumChar = (productSum, charCode, index) => {
  if (productSum < 0) return productSum

  const weight = WEIGHT_POSITIONS[index]
  const value = VALUES_FROM_ASCII[charCode]

  if (weight === -1) return productSum
  if (value < 0) return -1

  return productSum + (value * weight)
}

const validate = vin => {
  if (!vin || vin.length !== 17) return false
  vin = vin.toUpperCase()
  if (vin.replace(/[^A-Z0-9]+/g, '') !== vin) return false
  const charCodes = vin.split('').map(c => c.charCodeAt(0))
  const checkDigit = CHECK_DIGIT_FROM_ASCII[charCodes[8]]
  const productSum = charCodes.reduce(sumChar, 0)
  return productSum % 11 === checkDigit
}

export default validate
