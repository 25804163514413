const safe = (v, fn) => {
  if (typeof v === 'string') return fn(v)
  return v
}

export const normalizeUpperCaseWithNoSpaces = v => safe(v, s => s.replace(/\s/g, '').toUpperCase())

export const normalizePhone = (value, previousValue) => {
  if (value == null) return value

  const onlyNums = value.replace(/[^\d]/g, '')
  if (previousValue == null || value?.length > previousValue?.length) {
    if (onlyNums.length === 3) return onlyNums + '-'
    if (onlyNums.length === 6) return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    if (onlyNums.length === 10) return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6)
  }
  if (onlyNums.length <= 3) return onlyNums
  if (onlyNums.length <= 6) return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
  if (onlyNums.length <= 10) return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6)

  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10)
}
