import React from 'react'
import ReactMarkdown from 'react-markdown'
import useTranslate from '../../translations/useTranslate'
import './HelpText.scss'

const Help = ({ ariaLabelledById, body, className = '' }) =>
  <div className={'help-text ' + className} id={ariaLabelledById}>
    <ReactMarkdown source={body} />
  </div>

const translateIfExists = (i18n, t) => key => i18n.exists(key) ? t(key) : undefined

const HelpText = ({ label, className, fieldId, ariaLabelledById }) => {
  const { t, i18n } = useTranslate()
  const bodyTextKey = label + 'Help'

  const body = translateIfExists(i18n, t)(bodyTextKey)

  return body ? <Help body={body} fieldId={fieldId} ariaLabelledById={ariaLabelledById} className={className} /> : null
}

export default HelpText
