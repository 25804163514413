import { useField, useFieldIsValid, useFieldValue, useForm } from 'amiable-forms'
import { useCallback } from 'react'
import { Alert, Col, Input, Label } from 'reactstrap'
import useTranslate from '../../translations/useTranslate'
import { required } from '../../util/inputValidators'
import validateVinCheckDigit from '../../util/validateVinCheckDigit'
import FieldDecorations from '../Fields/FieldDecorations'

import './index.scss'

const ConfirmVin = ({ name }) => {
  const ConfirmVin = useFieldValue({ name })
  const vinOrPlateValue = useFieldValue({ name: 'vinOrPlate' })
  const vinOrPlateValid = useFieldIsValid({ name: 'vinOrPlate' })
  const { setValues } = useForm({ shouldUpdate: () => false })
  const { submitted, value, setValue } = useField({ name, validators: !vinOrPlateValid ? [required] : [] })
  const { t } = useTranslate()

  const onChange = useCallback(ev => {
    setValue(ev.target.checked)
    setValues(values => ({ ...values, vin: vinOrPlateValue }))
  }, [setValues, vinOrPlateValue, setValue])

  if (!submitted || validateVinCheckDigit(vinOrPlateValue) || !vinOrPlateValue || vinOrPlateValue?.length <= 10) return null

  return (
    <Col className='px-0'>
      <Alert className='pb-0' color={ConfirmVin ? 'success' : 'danger'}>
        <FieldDecorations inputType='checkbox'>
          <Label check className='confirm-vin pt-1'>
            <Input name={name} type='checkbox' checked={!!value} onChange={onChange} />
            {' ' + t('HomePage.confirmVin')}
          </Label>
        </FieldDecorations>
      </Alert>
    </Col>
  )
}

export default ConfirmVin
