const processMapper = state => (values, { shouldUpdate, mapper }) => shouldUpdate(state) ? mapper(values, state) : values

/**
 * @augments ...TransformMappers
 *
 * A Transform mapper is an object with two functions:
 * { shouldUpdate, mapper }
 *
 * The shouldUpdate function recieves the complete transform state and returns true if the mapper should be run
 *
 * `shouldUpdate = state => true/false`
 *
 * The mapper function recieves values and returns the mapped values
 *
 * `mapper = values => ({ ...values, newValue: 'foo' })`
 *
 */
const transformBuilder = (...transformMappers) => {
  return state => ({
    ...state.next,
    values: transformMappers.reduce(processMapper(state), state.next.values)
  })
}

export default transformBuilder
