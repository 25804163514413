import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import useTranslate from '../../translations/useTranslate'
import Header from '../Header'
import Footer from '../Footer'

import './index.scss'
import bgImage from '../../images/bg-map.png'

const PageContainer = ({ className = '', children }) => {
  const { t } = useTranslate()
  return (
    <>
      <div className='background-wrapper'>
        <img src={bgImage} alt={t('ImageAltText.altMapBg')} />
      </div>
      <Container className='page-wrapper'>
        <Header />
        <Row className={'content ' + className}>
          <Col>
            {children}
          </Col>
        </Row>
      </Container>
      <Container className='page-footer'>
        <Footer />
      </Container>
    </>
  )
}

export default PageContainer
