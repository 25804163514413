import React from 'react'
import { Row, Col } from 'reactstrap'

import useTranslate from '../../translations/useTranslate'
import addLogo from '../../images/logo-add.png'

import './index.scss'

const Footer = () => {
  const { t } = useTranslate()
  return (
    <Row className='my-2 footer align-items-center'>
      <Col sm={12} md={4} className='text-center text-md-left order-2 order-md-1 my-3 my-md-0'>
        <div><a href='/'>{t('Footer.url')}</a></div>
        <div>{t('Footer.address1')}</div>
        <div>{t('Footer.address2')}</div>
      </Col>
      <Col sm={12} md={4} className='text-center order-1 order-md-2'>
        <a href='/terms'>{t('Legal.terms')}</a> | <a href='/privacy'>{t('Legal.privacy')}</a>
      </Col>
      <Col sm={12} md={4} className='text-md-right text-center order-3 order-md-3'>
        <img src={addLogo} alt='Auto Data Direct, Inc.' className='footer-logo' />
      </Col>
    </Row>
  )
}

export default Footer
