import { vin } from '../../util/inputValidators'
import { fieldHasChanged, getFieldValue } from '../../util/transformUtil'

const shouldUpdateVin = state =>
  fieldHasChanged(state, ['vinOrPlate'])

export const vinMapper = (values, state) => {
  const vehicleVinOrPlateValue = getFieldValue(values, 'vinOrPlate')
  const confirmVinValue = getFieldValue(values, 'confirmVin')
  const vehicleVinOrPlateField = state?.current?.fields?.vinOrPlate
  const noConfirmVin = () => ({ ...values, confirmVin: false })

  if (!vehicleVinOrPlateField) {
    return noConfirmVin()
  }

  if (!vehicleVinOrPlateValue) {
    vehicleVinOrPlateField.error = 'ErrorFeedback.required'
    vehicleVinOrPlateField.valid = false
    state.current.values.confirmVin = false
    return noConfirmVin()
  }

  if (!vin(vehicleVinOrPlateValue)) {
    vehicleVinOrPlateField.error = undefined
    vehicleVinOrPlateField.valid = true
    state.current.values.confirmVin = false
    return noConfirmVin()
  }

  vehicleVinOrPlateField.error = !confirmVinValue ? 'ErrorFeedback.invalid' : undefined
  vehicleVinOrPlateField.valid = !!confirmVinValue

  return values
}

export const transformVin = {
  shouldUpdate: shouldUpdateVin,
  mapper: vinMapper
}
