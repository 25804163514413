import React, { useEffect, useState, useRef } from 'react'
import ReactMarkdown from 'react-markdown'

const load = async ({ url, setSource, mountedRef }) => {
  const response = await window.fetch(url)
  const text = await response.text()
  if (!mountedRef.current) return
  setSource(text)
}

const useMountedRef = () => {
  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => (mountedRef.current = false)
  }, [])
  return mountedRef
}

const RemoteMarkdown = ({ url }) => {
  const [source, setSource] = useState()
  const mountedRef = useMountedRef()

  useEffect(() => {
    load({ url, setSource, mountedRef })
  }, [url, setSource, mountedRef])

  return <ReactMarkdown source={source} />
}

export default RemoteMarkdown
