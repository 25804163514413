import { register } from 'use-async-ops'

import { defaultOptions, handleFetch } from '../util/fetch'
import * as asyncOpNames from '../constants/asyncOpNames'
import mocks from './mock'

const CARLOCATE = 'https://api.carlocate.com'

const carlocateApiService = handleFetch((path, options) => window.fetch(CARLOCATE + path, options))

const registerService = (code, service) => register(code, service, { mock: mocks[code] })

registerService(asyncOpNames.VEHICLE_SEARCH, ({ vinOrPlate, token }) => carlocateApiService(`/notices/${vinOrPlate}?token=${token}`, {
  ...defaultOptions,
  method: 'GET'
}))
